export const routes = {
  AUTH: {
    SIGN_IN: '/',
    FORGOT_PASSWORD: '/forgot-password',
    PASSWORD_RECOVERY: '/password-recovery',
  },
  JOBS: {
    LIST: '/jobs',
    CREATE: '/jobs/create',
    UPDATE: (jobId: string): string => `/jobs/${jobId}`,
  },
  COMPANIES: {
    LIST: '/companies',
    CREATE: '/companies/create',
    UPDATE: (companyId: string): string => `/companies/${companyId}`,
  },
};
