import { useTheme } from 'styled-components';

import { useMediaQuery } from '~/shared/hooks/useMediaQuery';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { Container, Content, ContentWrapper } from './styles';

import { Toolbar } from './Toolbar';
import { SideBar } from './SideBar';

export const DefaultLayout: FCWithChildren = ({ children }) => {
  const theme = useTheme();

  const isSmallerThan850 = useMediaQuery(
    `(max-width: ${theme.breakpoints.tablet})`
  );

  return (
    <Container>
      <Toolbar />

      <ContentWrapper>
        {!isSmallerThan850 && <SideBar />}

        <main id="main-element">
          <Content>{children}</Content>
        </main>
      </ContentWrapper>
    </Container>
  );
};
