import { memo, useCallback, useEffect, useRef } from 'react';

import { TModalSize } from '~/shared/hooks/useModal';
import { useClickOutside } from '~/shared/hooks/useClickOutside';
import { useClickEsc } from '~/shared/hooks/useClickEsc';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { Overlay, Content } from './styles';

import { ModalHeader } from './ModalHeader';

export interface IModalProps {
  id?: string;
  onClose?(): void;
  title?: string;
  size?: TModalSize;
  closeOnClickOutside?: boolean;
}

export const closeModal = (id: string): void => {
  const overlay = document.getElementById(`${id}-overlay`);
  const content = document.getElementById(`${id}-content`);

  if (overlay) {
    overlay.style.opacity = '0';
  }

  if (content) {
    content.style.opacity = '0';
    content.style.transform = 'translateY(36px)';
  }
};

export const Modal: FCWithChildren<IModalProps> = memo(
  ({
    onClose = () => null,
    children,
    title,
    id,
    size = 'sm',
    closeOnClickOutside = true,
  }) => {
    const overlayRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      setTimeout(() => {
        if (overlayRef.current) {
          overlayRef.current.style.opacity = '1';
        }

        if (contentRef.current) {
          contentRef.current.style.opacity = '1';
          contentRef.current.style.transform = 'translateY(0)';
        }
      }, 1);
    }, []);

    const close = useCallback(() => {
      if (overlayRef.current) {
        overlayRef.current.style.opacity = '0';
      }

      if (contentRef.current) {
        contentRef.current.style.opacity = '0';
        contentRef.current.style.transform = 'translateY(36px)';
      }

      setTimeout(() => {
        onClose();
      }, 1);
    }, [onClose]);

    const handleClose = useCallback(
      (closeOnX?: boolean) => {
        if (closeOnClickOutside || closeOnX) {
          close();
        }
      },
      [close, closeOnClickOutside]
    );

    const containerRef = useClickOutside<HTMLDivElement>(handleClose);

    useClickEsc(close);

    return (
      <Overlay ref={overlayRef} id={`${id}-overlay`} size={size}>
        <div ref={contentRef} id={`${id}-content`}>
          <Content ref={containerRef}>
            {!!title && <ModalHeader title={title} onClose={handleClose} />}

            {children}
          </Content>
        </div>
      </Overlay>
    );
  }
);
