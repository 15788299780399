import { FC } from 'react';

import Image from 'next/image';

import querJobsLogoImg from '~/shared/assets/images/quero-jobs-logo.png';

import {
  Container,
  ActionsWrapper,
  LeftContent,
  LogoContainer,
} from './styles';

import { MyAccount } from './MyAccount';
import { SideBarDrawer } from '../SideBarDrawer';

export const Toolbar: FC = () => {
  return (
    <>
      <Container>
        <div>
          <LeftContent>
            <SideBarDrawer />

            <LogoContainer>
              <Image
                height={40}
                width={110}
                alt="QueroJobs"
                src={querJobsLogoImg.src}
              />
            </LogoContainer>
          </LeftContent>

          <ActionsWrapper>
            {/* <ToolbarIconButton
              tooltip="Mensagens - Em breve"
              icon={FiMessageCircle}
            />

            <ToolbarIconButton
              tooltip="Notificações - Em breve"
              icon={FiBell}
            /> */}

            <MyAccount />
          </ActionsWrapper>
        </div>
      </Container>
    </>
  );
};
