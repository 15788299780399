import { FC, Fragment, useMemo } from 'react';

import { TbBuilding } from 'react-icons/tb';
import { FiBriefcase } from 'react-icons/fi';

import { routes } from '~/shared/constants/routes';

import { Container } from './styles';

import { NavMenu } from './NavMenu';
import { NavItem, INavItemProps } from './NavItem';

export const SideBarNav: FC = () => {
  const navItems: Pick<
    INavItemProps,
    | 'href'
    | 'label'
    | 'icon'
    | 'shouldMatchExactHref'
    | 'comingSoon'
    | 'menu'
    | 'permissions'
  >[] = useMemo(
    () => [
      {
        href: routes.COMPANIES.LIST,
        icon: TbBuilding,
        label: 'Empresas',
      },
      {
        href: routes.JOBS.LIST,
        icon: FiBriefcase,
        label: 'Vagas',
      },
    ],
    []
  );

  return (
    <Container>
      <ul>
        {navItems.map((item) => (
          <Fragment key={`${String(item.href)}-${item.label}`}>
            {item.menu?.length > 0 ? (
              <>
                {item.menu.some(
                  (itemMenu) =>
                    (itemMenu.permissions || []).length === 0 ||
                    itemMenu.permissions.some((p) => !!p)
                ) && <NavMenu {...item} />}
              </>
            ) : (
              <>
                {((item.permissions || []).length === 0 ||
                  item.permissions.some((p) => !!p)) && <NavItem {...item} />}
              </>
            )}
          </Fragment>
        ))}
      </ul>
    </Container>
  );
};
