import { api } from '~/shared/services/api';

import { ISignInResponse } from '../interfaces/ISignInResponse';
import { ISignInCredentials } from '../interfaces/ISignInCredentials';

export async function signIn(
  credentials: ISignInCredentials
): Promise<ISignInResponse> {
  const encodedUserCredentials = Buffer.from(
    `${credentials.email}:${credentials.password}`
  ).toString('base64');

  const response = await api.post<ISignInResponse>(
    '/admin-auth/token',
    { grantType: 'user_credentials' },
    { headers: { Authorization: `Basic ${encodedUserCredentials}` } }
  );

  return response.data;
}
