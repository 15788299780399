import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: center;

  width: 100%;

  background: ${({ theme }) => theme.colors.surface};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};

  z-index: 15;

  > div {
    padding: ${({ theme }) => `0 ${theme.spacing.md}`};
    height: 68px;
    width: 100%;

    display: flex;
    align-items: center;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;

  flex: 1;
  overflow: hidden;
  margin-right: ${({ theme }) => theme.spacing['2xs']};

  gap: ${({ theme }) => theme.spacing.xs};
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    object-fit: contain;
    object-position: left;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const ActionsWrapper = styled.ul`
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.spacing.md};

  > li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
