import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { FiMenu } from 'react-icons/fi';

import { useRouter } from 'next/router';

import { useMediaQuery } from '~/shared/hooks/useMediaQuery';

import { SideBarBackdrop, Container } from './styles';

import { ToolbarIconButton } from '../Toolbar/ToolbarIconButton';
import { SideBar } from '../SideBar';

export const SideBarDrawer: FC = () => {
  const router = useRouter();

  const isSmallerThan860 = useMediaQuery('(max-width: 860px)');

  const containerRef = useRef<HTMLDivElement>(null);
  const sideBarRef = useRef<HTMLElement>(null);
  const sideBarBackdropRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => {
    if (!isSmallerThan860) return;

    if (
      sideBarRef.current &&
      sideBarBackdropRef.current &&
      containerRef.current
    ) {
      sideBarRef.current.style.transform = 'translateX(-300px)';
      sideBarBackdropRef.current.style.opacity = '0';
      sideBarBackdropRef.current.style.cursor = 'default';

      setTimeout(() => {
        containerRef.current.style.display = 'none';
        setIsOpen(false);
      }, 500);
    }
  }, [isSmallerThan860]);

  const open = useCallback(() => {
    if (!isSmallerThan860) return;

    setIsOpen(true);

    if (
      sideBarRef.current &&
      sideBarBackdropRef.current &&
      containerRef.current
    ) {
      containerRef.current.style.display = 'block';
      sideBarBackdropRef.current.style.cursor = 'pointer';

      setTimeout(() => {
        sideBarRef.current.style.transform = 'translateX(0)';
        sideBarBackdropRef.current.style.opacity = '1';
      }, 100);
    }
  }, [isSmallerThan860]);

  const toggle = useCallback(() => {
    isOpen ? close() : open();
  }, [close, isOpen, open]);

  useEffect(() => {
    router.events.on('routeChangeComplete', close);

    return () => {
      router.events.off('routeChangeComplete', close);
    };
  }, [close, router.events]);

  useEffect(() => {
    setIsOpen(false);
  }, [isSmallerThan860]);

  if (!isSmallerThan860) return null;

  return (
    <>
      <ToolbarIconButton
        tooltip={isOpen ? 'Fechar menu' : 'Abrir menu'}
        icon={FiMenu}
        onClick={toggle}
      />

      <Container ref={containerRef}>
        <SideBar ref={sideBarRef} onClose={close} />

        <SideBarBackdrop ref={sideBarBackdropRef} onClick={close} />
      </Container>
    </>
  );
};
