/* eslint-disable import/no-cycle */
import Router from 'next/router';

import { destroyCookie, parseCookies, setCookie } from 'nookies';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { AuthTokenError } from '~/shared/errors/AuthTokenError';

import { routes } from '~/shared/constants/routes';
import { envs } from '~/shared/constants/envs';
import { cookies } from '~/shared/constants/cookies';

export function setupApi(ctx = undefined): AxiosInstance {
  const parsedCookies = parseCookies(ctx);

  const api = axios.create({
    baseURL: envs.API_URL,
    headers: {
      ...(parsedCookies &&
        parsedCookies[cookies.AUTH_ACCESS_TOKEN] && {
          Authorization: `Bearer ${parsedCookies[cookies.AUTH_ACCESS_TOKEN]}`,
        }),
    },
  });

  api.interceptors.response.use(
    (success) => success,
    (error: AxiosError<any>) => {
      if (error.response?.status === 401) {
        if (error.response?.data?.code === 'token_expired') {
          if (typeof window !== 'undefined') {
            destroyCookie(null, cookies.AUTH_ACCESS_TOKEN, { path: '/' });
            destroyCookie(null, cookies.AUTH_REFRESH_TOKEN, { path: '/' });
            api.defaults.headers['Authorization'] = '';
            Router.push(routes.AUTH.SIGN_IN);
          } else {
            return Promise.reject(new AuthTokenError());
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
}

export const api = setupApi();

export const setApiDefaults = (
  accessToken?: string,
  refreshToken?: string
): void => {
  if (!api) return;

  if (!accessToken || !refreshToken) {
    destroyCookie(null, cookies.AUTH_ACCESS_TOKEN, { path: '/' });
    destroyCookie(null, cookies.AUTH_REFRESH_TOKEN, { path: '/' });
    api.defaults.headers['Authorization'] = '';
    return;
  }

  api.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  setCookie(null, cookies.AUTH_ACCESS_TOKEN, accessToken, { path: '/' });
  setCookie(null, cookies.AUTH_REFRESH_TOKEN, refreshToken, { path: '/' });
};
