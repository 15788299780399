import { memo, useCallback, useEffect, useRef } from 'react';

import { useClickOutside } from '~/shared/hooks/useClickOutside';
import { useClickEsc } from '~/shared/hooks/useClickEsc';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { Overlay, Content } from './styles';

import { ModalHeader } from '../Modal/ModalHeader';

export interface ISideBarProps {
  id?: string;
  onClose?(): void;
  title?: string;
}

export const closeSideBar = (id: string): void => {
  const overlay = document.getElementById(`${id}-overlay`);
  const content = document.getElementById(`${id}-content`);

  if (overlay) {
    overlay.style.opacity = '0';
  }

  if (content) {
    content.style.opacity = '0';
    content.style.transform = 'translateX(400px)';
  }
};

export const SideBar: FCWithChildren<ISideBarProps> = memo(
  ({ onClose = () => null, children, title, id }) => {
    const overlayRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      setTimeout(() => {
        if (overlayRef.current) {
          overlayRef.current.style.opacity = '1';
        }

        if (contentRef.current) {
          contentRef.current.style.opacity = '1';
          contentRef.current.style.transform = 'translateX(0)';
        }
      }, 1);
    }, []);

    const handleClose = useCallback(() => {
      if (overlayRef.current) {
        overlayRef.current.style.opacity = '0';
      }

      if (contentRef.current) {
        contentRef.current.style.opacity = '0';
        contentRef.current.style.transform = 'translateX(400px)';
      }

      setTimeout(() => {
        onClose();
      }, 1);
    }, [onClose]);

    const containerRef = useClickOutside<HTMLDivElement>(handleClose);

    useClickEsc(handleClose);

    return (
      <Overlay ref={overlayRef} id={`${id}-overlay`}>
        <div ref={contentRef} id={`${id}-content`}>
          <Content ref={containerRef}>
            {!!title && <ModalHeader title={title} onClose={handleClose} />}

            {children}
          </Content>
        </div>
      </Overlay>
    );
  }
);
