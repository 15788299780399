import { ThemeProvider } from 'styled-components';
import { ChakraProvider } from '@chakra-ui/react';

import { SideBarProvider } from '~/shared/hooks/useSideBar';
import { ModalProvider } from '~/shared/hooks/useModal';
import { AuthProvider } from '~/modules/auth/hooks/useAuth';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { theme } from '~/shared/styles/theme';

export const AppProvider: FCWithChildren = ({ children }) => {
  return (
    <ChakraProvider resetCSS={false}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ModalProvider>
            <SideBarProvider>{children}</SideBarProvider>
          </ModalProvider>
        </AuthProvider>
      </ThemeProvider>
    </ChakraProvider>
  );
};
