export const unformatCurrency = (value: string): number =>
  value ? Number(value.replace(/\D/g, '')) / 100 : 0;

export const formatCurrency = (value: number | string): string => {
  const newValue = typeof value === 'string' ? unformatCurrency(value) : value;

  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(newValue);
};
