import { api } from '~/shared/services/api';

import { IRefreshTokenResponse } from '../interfaces/IRefreshTokenResponse';

export async function refreshToken(
  _refreshToken: string
): Promise<IRefreshTokenResponse> {
  const response = await api.post<IRefreshTokenResponse>('/admin-auth/token', {
    grantType: 'refresh_token',
    refreshToken: _refreshToken,
  });

  return response.data;
}
