import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { Container, Content } from './styles';

export const AuthLayout: FCWithChildren = ({ children }) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
};
