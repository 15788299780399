import { createContext, useContext, useState, useCallback } from 'react';

import { v4 as uuidV4 } from 'uuid';

import { closeModal, Modal } from '~/shared/components/Modal';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

export type TModalSize = 'sm' | 'md' | 'lg' | 'full';

interface IModal {
  content: JSX.Element;
  id: string;
  title?: string;
  size?: TModalSize;
  closeOnClickOutside?: boolean;
}

type CloseFn = (id: string) => void;

export interface IModalCallbackProps {
  onClose(): void;
  id: string;
  setTitle?(title: string): void;
}

interface IOpenModalOptions {
  title: string;
  size?: TModalSize;
  closeOnClickOutside?: boolean;
}

type ContentCallback = (props: IModalCallbackProps) => JSX.Element;

interface IModalContextData {
  open(content: ContentCallback, options?: IOpenModalOptions): void;
  close: CloseFn;
}

const ModalContext = createContext({} as IModalContextData);

const ModalProvider: FCWithChildren = ({ children }) => {
  const [modals, setModals] = useState<IModal[]>([]);

  const close = useCallback((id: string) => {
    closeModal(id);

    setTimeout(() => {
      setModals((prevState) => prevState.filter((item) => item.id !== id));
    }, 400);
  }, []);

  const setTitle = useCallback((id: string, title: string) => {
    setModals((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          return { ...item, title };
        }

        return item;
      })
    );
  }, []);

  const open = useCallback(
    (content: ContentCallback, options?: IOpenModalOptions) => {
      setModals((prevState) => {
        const id = uuidV4();

        const props: IModalCallbackProps = {
          id,
          onClose: () => close(id),
          setTitle: (title: string) => setTitle(id, title),
        };

        return [
          ...prevState,
          {
            content: content(props),
            id,
            title: options?.title,
            size: options?.size,
            closeOnClickOutside:
              options?.closeOnClickOutside === null ||
              options?.closeOnClickOutside === undefined
                ? true
                : options?.closeOnClickOutside,
          },
        ];
      });
    },
    [close, setTitle]
  );

  return (
    <ModalContext.Provider value={{ open, close }}>
      {modals.map(({ content, id, title, size, closeOnClickOutside }) => (
        <Modal
          key={id}
          onClose={() => close(id)}
          id={id}
          title={title}
          size={size}
          closeOnClickOutside={closeOnClickOutside}
        >
          {content}
        </Modal>
      ))}

      {children}
    </ModalContext.Provider>
  );
};

const useModal = (): IModalContextData => {
  return useContext(ModalContext);
};

export { ModalProvider, useModal };
