/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @next/next/no-css-tags */
/* eslint-disable @next/next/no-sync-scripts */
import Head from 'next/head';
import type { AppProps } from 'next/app';

import { routes } from '~/shared/constants/routes';

import { TopBar } from '~/shared/components/TopBar';
import { DefaultLayout } from '~/shared/components/DefaultLayout';
import { AuthLayout } from '~/shared/components/AuthLayout';
import { AppProvider } from '~/shared/components/AppProvider';

import { GlobalStyle } from '~/shared/styles/global';

import '~/shared/utils/yupValidations';

const authRoutes = [...Object.values(routes.AUTH)];

export default function MyApp({
  Component,
  pageProps,
  router,
}: AppProps): JSX.Element {
  const Layout = authRoutes.includes(
    router.pathname === '/' ? '/' : router.pathname
  )
    ? AuthLayout
    : DefaultLayout;

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="msapplication-TileColor" content="#203578" />
        <meta name="theme-color" content="#203578" />
        <meta name="description" content="..." />

        <title>Portal do Administrador | QueroJobs</title>
      </Head>

      <AppProvider>
        <GlobalStyle />

        <TopBar />

        <Layout>
          <Component {...pageProps} />
        </Layout>
      </AppProvider>
    </>
  );
}
