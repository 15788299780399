import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useInsertionEffect,
} from 'react';

import { useRouter } from 'next/router';

import { parseCookies } from 'nookies';
import { AxiosError } from 'axios';

import { routes } from '~/shared/constants/routes';
import { cookies } from '~/shared/constants/cookies';

import { useToast } from '~/shared/hooks/useToast';

import { setApiDefaults } from '~/shared/services/api';
import { signIn as apiSignIn } from '~/modules/auth/services/signIn';
import { refreshToken as apiRefreshToken } from '~/modules/auth/services/refreshToken';

import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { ISignInCredentials } from '~/modules/auth/interfaces/ISignInCredentials';

interface IAuthContextData {
  user: any;
  signIn(credentials: ISignInCredentials): Promise<any>;
  signOut(): void;
  isAuthenticated: boolean;
}

const AuthContext = createContext({} as IAuthContextData);

const AuthProvider: FCWithChildren = ({ children }) => {
  const router = useRouter();
  const toast = useToast();

  const [user, setUser] = useState<any | null>(null);

  const isAuthenticated = useMemo(() => !!user, [user]);

  const signOut = useCallback(async () => {
    setApiDefaults();

    await router.push(routes.AUTH.SIGN_IN);

    setUser(null);
  }, [router]);

  const signIn = useCallback(
    async (credentials: ISignInCredentials) => {
      try {
        const apiResponse = await apiSignIn(credentials);

        setApiDefaults(apiResponse.accessToken, apiResponse.refreshToken);

        const apiUser = apiResponse.user;

        setUser(apiUser);

        router.push(routes.JOBS.LIST);

        return apiUser;
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 401) {
          toast.show({
            title: 'Ops, credenciais inválida(s)!',
            description: 'Seu e-mail e/ou senha está(ão) incorreto(s).',
            variant: 'error',
          });
          return;
        }

        toast.show({
          title: 'Ops, não foi possível fazer o login!',
          description: 'Recarregue a página e tente novamente.',
          variant: 'error',
        });
      }
    },
    [router, toast]
  );

  useInsertionEffect(() => {
    (async () => {
      const { [cookies.AUTH_REFRESH_TOKEN]: refreshToken } = parseCookies();

      if (refreshToken) {
        try {
          const refreshTokenResponse = await apiRefreshToken(refreshToken);

          if (refreshTokenResponse) {
            setUser(refreshTokenResponse.user);
            setApiDefaults(
              refreshTokenResponse.accessToken,
              refreshTokenResponse.refreshToken
            );
          } else {
            signOut();
          }
        } catch {
          signOut();
        }
      }
    })();
  }, []);

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): IAuthContextData => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
