/* eslint-disable func-names */
import * as yup from 'yup';

import { validatePhoneNumber } from './validatePhoneNumber';
import { validateNaturalDocument } from './validateNaturalDocument';
import { validateLegalDocument } from './validateLegalDocument';
import { validateDocument } from './validateDocument';
import { unformatCurrency } from './formatCurrency';

yup.addMethod(yup.string, 'phoneNumber', function (errorMessage) {
  return this.test('test-phone-number-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validatePhoneNumber(value) || createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(yup.string, 'naturalDocument', function (errorMessage) {
  return this.test('test-document-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validateNaturalDocument(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(yup.string, 'legalDocument', function (errorMessage) {
  return this.test('test-document-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validateLegalDocument(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(yup.string, 'document', function (errorMessage) {
  return this.test('test-document-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validateDocument(value) || createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(yup.string, 'currency', function (errorMessage) {
  return this.test('test-price-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    const formattedValue = unformatCurrency(value);
    const hasValue = formattedValue > 0;

    return hasValue || createError({ path, message: errorMessage });
  });
});
