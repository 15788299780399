import styled, { keyframes } from 'styled-components';

const fadeFromBottomAnimation = keyframes`
  from { opacity: 0; transform: translateY(60px); }
  to { opacity: 1 }
`;

export const Container = styled.main`
  max-height: 100vh;
  height: 100%;
  width: 100%;

  display: grid;
  place-items: center;

  overflow-y: auto;
  overflow-x: hidden;
`;

export const Content = styled.section`
  height: 100%;
  width: 100%;
  max-width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm};

  padding: ${({ theme }) => theme.spacing.lg};

  > aside,
  > form {
    animation: ${fadeFromBottomAnimation} 0.7s ease-out forwards;
    opacity: 0;
    animation-delay: 0.2s;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.lg};
    height: initial;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.md};
  }
`;
