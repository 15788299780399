import { FC, useEffect, useState } from 'react';

import { FiX } from 'react-icons/fi';

import { useRouter } from 'next/router';

import { ScaleFade } from '@chakra-ui/react';

import { useClickOutside } from '~/shared/hooks/useClickOutside';
import { useAuth } from '~/modules/auth/hooks/useAuth';

import { Tooltip } from '~/shared/components/Tooltip';
import { Avatar } from '~/shared/components/Avatar';

import {
  Container,
  AvatarButton,
  PopoverContainer,
  ClosePopoverButton,
  PopoverContent,
} from './styles';

export const MyAccount: FC = () => {
  const router = useRouter();

  const { user, signOut } = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  function closePopover(): void {
    setIsOpen(false);
  }

  useEffect(() => {
    router.events.on('routeChangeStart', closePopover);

    return () => {
      router.events.off('routeChangeStart', closePopover);
    };
  }, [router]);

  const popoverRef = useClickOutside<HTMLLIElement>(closePopover);

  useEffect(() => {
    document.addEventListener('keydown', closePopover, false);

    return () => {
      document.removeEventListener('keydown', closePopover, false);
    };
  }, []);

  function tooglePopperIsOpen(): void {
    setIsOpen((prevState) => !prevState);
  }

  return (
    <Container ref={popoverRef}>
      <AvatarButton type="button" onClick={tooglePopperIsOpen}>
        <Avatar alt={user?.name} />
      </AvatarButton>

      <ScaleFade in={isOpen} initialScale={0.9} unmountOnExit>
        <PopoverContainer>
          <Tooltip label="Fechar">
            <ClosePopoverButton type="button" onClick={closePopover}>
              <FiX />
            </ClosePopoverButton>
          </Tooltip>

          <PopoverContent>
            <div>
              <Avatar alt={user?.name} size={60} iconSize={20} />
              <strong>{user?.name}</strong>
              <span>{user?.email}</span>
            </div>

            {/* {!!user?.resale?._id && (
              <Link
                href={routes.RESALES.SHOW_SUBSCRIPTION_DETAILS(user.resale._id)}
                passHref
              >
                <a>Ver assinatura</a>
              </Link>
            )} */}

            <button type="button" onClick={signOut}>
              Sair do portal
            </button>
          </PopoverContent>
        </PopoverContainer>
      </ScaleFade>
    </Container>
  );
};
