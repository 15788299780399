import { ReactNode } from 'react';

import {
  PlacementWithLogical,
  Tooltip as ChakraTooltip,
} from '@chakra-ui/react';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

interface ITooltipProps {
  label: ReactNode;
  placement?: PlacementWithLogical;
  isDisabled?: boolean;
  marginLeft?: number;
}

export const Tooltip: FCWithChildren<ITooltipProps> = ({
  label,
  placement = 'bottom',
  children,
  isDisabled = false,
  marginLeft,
}) => {
  return (
    <ChakraTooltip
      label={label}
      hasArrow
      closeOnClick={false}
      placement={placement}
      isDisabled={isDisabled}
      marginLeft={marginLeft}
    >
      {children}
    </ChakraTooltip>
  );
};
